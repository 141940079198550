import React, { createContext, ReactNode, useState } from "react";
import jwtManager from "../utils/jwtManager";
import configs from "../appConfigs";
import axios from "axios";

const AuthContext: React.Context<any> = createContext({});
export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [auth, setAuth] = useState(jwtManager.getTokens());
  const authHandler = {
    login: async ({ googleOauthCode }: { googleOauthCode: string }) => {
      const response = await axios.get(
        `${configs.apiBaseUrl}/api/sessions/oauth/google?code=${googleOauthCode}`
      );
      setAuth(response.data.tokens);

      fetch(
        `https://www.googleapis.com/oauth2/v3/userinfo?access_token=${response.data.tokens.access_token}`
      )
        .then((response) => response.json())
        .then((userInfo) => {
          if (userInfo.email) {
            const userEmail = userInfo.email;
            localStorage.setItem("Email", userEmail);
          }
        });
      jwtManager.setToken(response.data.tokens);
      jwtManager.loadTokens();
      return Promise.resolve();
    },

    logout: () => {
      setAuth(null);
      jwtManager.ereaseToken();
      return Promise.resolve();
    },

    checkAuth: () => {
      return jwtManager.getTokens() ? Promise.resolve() : Promise.reject();
    },

    checkError: (error: any) => {
      const status = error.status;
      if (status === 401 || status === 403) {
        setAuth(null);
        jwtManager.ereaseToken();
        return Promise.reject();
      }
      return Promise.resolve();
    },

    getPermissions: () => {
      //todo return role perms
      return jwtManager.getTokens() ? Promise.resolve() : Promise.reject();
    },
  };
  return (
    <AuthContext.Provider value={{ auth, setAuth, authHandler }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
