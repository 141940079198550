import axios from "axios";

export interface tokensType {
  id_token: string;
  access_token: string;
  refresh_token: string;
}

const jwtManager = () => {
  const getTokens = () => {
    if (localStorage.getItem("tokens"))
      return JSON.parse(localStorage.getItem("tokens") as string);
    return null;
  };

  const setToken = (tokens: tokensType) => {
    if (tokens) {
      localStorage.setItem("tokens", JSON.stringify(tokens));
      return true;
    }
  };

  const ereaseToken = () => {
    localStorage.removeItem("tokens");
    return true;
  };

  const setAuthToken = (tokens: tokensType) => {
    if (tokens.refresh_token) {
      return (axios.defaults.headers.common[
        "Authorization"
      ] = `${tokens.refresh_token} Bearer:${tokens.id_token}`);
    }
    return delete axios.defaults.headers.common["Authorization"];
  };

  const loadTokens = () => {
    const tokens = getTokens();
    if (tokens?.refresh_token) {
      setAuthToken(tokens);
    }
  };

  return {
    loadTokens,
    ereaseToken,
    getTokens,
    setToken,
  };
};

export default jwtManager();
