import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import Dropdown from "../../components/BannerManager/Dropdown";
import Button from "../../components/Button";
import {
  CategoryDetails,
  getCategoryDetails,
  getImmediateSubCategories,
  postCategoryOrderBy,
  updateMetatags,
} from "../../services/categoriesService";

const filtersOptions = [
  {
    title: "A>Z",
    value: "A>Z",
  },
  {
    title: "Z>A",
    value: "Z>A",
  },
  {
    title: "Menor Preço",
    value: "Menor Preço",
  },
  {
    title: "Maior Preço",
    value: "Maior Preço",
  },
  {
    title: "Data de Lançamento",
    value: "Data de Lançamento",
  },
];

export default function CategoriesOrderBy() {
  const { id } = useParams();
  const location = useLocation() as { state: { categoryName: string } };
  const navigate = useNavigate();
  const [departmentId, setDepartmentId] = useState(Number(id) || null);
  const [categoryId, setCategoryId] = useState(null);
  const [subCategoryId, setSubCategoryId] = useState(null);
  const [orderBy, setOrderBy] = useState(null);
  const [loading, setLoading] = useState(false);

  const [categoryOptions, setCategoryOptions] = useState([]);
  const [subCategoryOptions, setSubCategoryOptions] = useState([]);
  const [metatagsInputData, setMetatagsInputData] = useState<CategoryDetails>(
    {} as CategoryDetails
  );

  async function getCategory(categoryId: number) {
    const response = await getCategoryDetails(categoryId);

    setMetatagsInputData(response as CategoryDetails);
    setOrderBy(response?.orderBy);
  }

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setMetatagsInputData({
      ...metatagsInputData,
      [e.target.name]: e.target.value,
    });
  };

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setLoading(true);

    try {
      await postCategoryOrderBy({
        categoryId: subCategoryId || categoryId || departmentId,
        orderBy,
      });

      await updateMetatags(
        subCategoryId || categoryId || departmentId,
        metatagsInputData
      );

      toast.success(`Categoria atualizada com sucesso!`, {
        pauseOnFocusLoss: false,
        onClose: () => {
          navigate("/categories");
          setLoading(false);
        },
      });
    } catch (error) {
      setLoading(false);
      toast.error(
        `Erro ao tentar atualizar orderBy da categoria \n", ${error}`
      );
    }
  }

  useEffect(() => {
    if (id) {
      getCategory(Number(id));
    }
  }, []);

  useEffect(() => {
    if (id) return;

    setCategoryId(null);
    setCategoryOptions([]);
    departmentId &&
      getImmediateSubCategories({
        parentCategoryId: departmentId,
      }).then((res) => {
        setCategoryOptions(res);
        getCategory(departmentId);
      });
  }, [departmentId, id]);

  useEffect(() => {
    setSubCategoryId(null);
    setSubCategoryOptions([]);
    categoryId &&
      getImmediateSubCategories({
        parentCategoryId: categoryId,
      }).then((res) => {
        setSubCategoryOptions(res);
        getCategory(categoryId);
      });
  }, [categoryId]);

  return (
    <section className="wrapper">
      <Title>
        {location?.state?.categoryName
          ? `Ordenando ${location.state.categoryName} Id: ${id}`
          : "Ordenação de categorias"}
      </Title>
      <CategoriesOrderByContainer onSubmit={handleSubmit}>
        {!id && (
          <InputContainer>
            <Dropdown
              required={true}
              title={`Escolha um departamento ${
                departmentId ? `Id: ${departmentId}` : ""
              }`}
              selected={departmentId}
              setSelected={setDepartmentId}
              options={async () =>
                await getImmediateSubCategories({ parentCategoryId: 101 })
              }
            />

            {!!categoryOptions.length && (
              <Dropdown
                title={`Escolha uma categoria ${
                  categoryId ? `Id: ${categoryId}` : ""
                }`}
                selected={categoryId}
                setSelected={setCategoryId}
                options={categoryOptions}
              />
            )}

            {!!subCategoryOptions.length && (
              <Dropdown
                title={`Escolha uma subcategoria ${
                  subCategoryId ? `Id: ${subCategoryId}` : ""
                }`}
                selected={subCategoryId}
                setSelected={setSubCategoryId}
                options={subCategoryOptions}
              />
            )}
          </InputContainer>
        )}

        <InputContainer>
          <Label>Titulo da metatag: </Label>
          <Input
            type="text"
            name="title"
            onChange={handleInputChange}
            maxLength={60}
            autoComplete="off"
            value={metatagsInputData?.title}
          />
          <Label>Descrição da metatag: </Label>
          <TextArea
            type="text"
            name="description"
            onChange={handleInputChange}
            maxLength={160}
            autoComplete="off"
            value={metatagsInputData?.description}
          />
          <Label>Keywords da metatag (dividos por virgula): </Label>
          <Input
            type="text"
            name="keywords"
            onChange={handleInputChange}
            maxLength={255}
            autoComplete="off"
            value={metatagsInputData?.keywords}
          />

          <Dropdown
            required={true}
            title={`Escolha uma ordenação`}
            selected={orderBy}
            setSelected={setOrderBy}
            options={filtersOptions}
          />
          <Button variant="light" disabled={loading}>
            Enviar
          </Button>
        </InputContainer>
      </CategoriesOrderByContainer>
    </section>
  );
}

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

const Label = styled.label`
  margin-top: 1rem;
`;

const Input = styled.input`
  width: 31.25rem;
  border-radius: 7px;
  margin: 0.5rem 0;
  border: ${(props) =>
    props.disabled ? "1px solid #a8a8a8;" : "1px solid #85b074;"};
  color: ${(props) => props.disabled && "#c6c6c6;"};
  font-size: 1rem;
  padding: 0.5rem;
  &:focus {
    outline: none;
    border: 1px solid #6d9962;
  }
  text-align: flex-start;
`;

const TextArea = styled.textarea<{ type: string }>`
  width: 31.25rem;
  height: 5rem;
  border-radius: 7px;
  margin: 0.5rem 0;
  border: ${(props) =>
    props.disabled ? "1px solid #a8a8a8;" : "1px solid #85b074;"};
  color: ${(props) => props.disabled && "#c6c6c6;"};
  font-size: 1rem;
  padding: 0.5rem;
  &:focus {
    outline: none;
    border: 1px solid #6d9962;
  }
  text-align: flex-start;
`;

const CategoriesOrderByContainer = styled.form`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 25%;

  margin: 3rem 0;
  border-top: 1px solid ${(props) => props.theme.colors.border};
  padding-top: 3rem;

  strong {
    font-size: 1.5rem;
  }
`;

const Title = styled.p`
  color: ${(props) => props.theme.colors.text_dark};
  font-size: 1.9rem;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 3rem;
`;
